<template>
    <div class="modal-mask">
        <div class="modal-container">
            <div class="new-resale-modal-header">
                {{ title }}
                <span v-on:click="close()" class="material-icons new-resale-modal-close">cancel</span>
            </div>
            <div class="new-resale-modal-body" v-if="loading">
                <cc-loader style="margin-top: 10vh;"/>
            </div>
            <div class="new-resale-modal-body" v-else>
                <div class="new-resale-modal-body-inputs-line">
                    <StandardInput :action="setName" :value="resale.name" :auto="true" class="new-resale-modal-input" title='Nome' type="text" />
                    <StandardInput :action="setCnpj" :value="resale.cnpj" mask="## ### ###/####-##" :auto="true" class="new-resale-modal-input" title='CNPJ' type="text" />
                </div>
                <div class="new-resale-modal-body-inputs-line">
                    <StandardInput :action="setPhone" :value="resale.phone" mask="(##) #.####-####" :auto="true" class="new-resale-modal-input" title='Telefone' type="text" />
                    <StandardInput :action="setEmail" :value="resale.email" :auto="true" class="new-resale-modal-input" title='Email' type="text" />
                </div>
                <div class="new-resale-modal-body-inputs-line">
                    <SelectCheckFiltered :placeholder="resale.est_nome" :action="setState"  class="new-resale-modal-input" title='Estado' type="select" :list="states" />
                    <SelectCheckFiltered :placeholder="resale.cid_nome" :action="value => resale.cid_id = value.id"  class="new-resale-modal-input" title='Cidade' type="select" :list="available_cities"/>
                </div>
                <div class="new-resale-modal-body-inputs-line">
                    <StandardInput :action="setContact" :value="resale.contact" :auto="true" class="new-resale-modal-input" title='Contato' type="text" />
                    <StandardInput :action="setSite" :auto="true" :value="resale.site" class="new-resale-modal-input" title='Site' type="text" />
                </div>
                <div class="new-resale-modal-body-buttons">
                    <StandardButton class="new-resale-modal-buttons-mobile" :action="close" text="Cancelar"/>
                    <StandardButton class="new-resale-modal-buttons-mobile" :action="save" text="Salvar"/>
                </div>
            </div>
        </div>
    </div>
	<!-- <cc-modal :modal="modal" @close="close">
		<div slot="body" class="container-fluid pt-0 sellers">
			<div class="row contents shadow">
                <div class="col" v-show="resale">
                    <ValidationObserver v-slot="{ handleSubmit }">
                        <form action="" v-if=" !loading" @submit.prevent="handleSubmit(save)">

                            <div class="row">
                                <div class="col col-md-4 col-lg-5">
                                    <label>Nome:<span style="color:red !important">*</span></label>
                                    <ValidationProvider rules="required" v-slot="v">
                                        <cc-input type="text" id="resale-name" v-model="resale.name" />
                                        <span class="error">{{ v.errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="col col-md-3 col-lg-4">
                                    <label>CNPJ:<span style="color:red !important">*</span></label>
                                    <ValidationProvider rules="required|cnpj" v-slot="v">
                                        <cc-input type="text" id="resale-cnpj" v-mask="'## ### ###/####-##'" v-model="resale.cnpj"></cc-input>
                                        <span class="error">{{ v.errors[0] }}</span>
                                    </ValidationProvider>
                                </div>

                                <div class="col col-md-2 col-lg-3">
                                    <label>Telefone:<span style="color:red !important">*</span></label>
                                    <ValidationProvider rules="required" v-slot="v">
                                        <cc-input type="text"
                                            id="resale-phone"
                                            v-mask="'(##) #.####-####'"
                                            v-model="resale.phone" />
                                        <span class="error">{{ v.errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col col-md-3 col-lg-4">
                                    <label>Email:<span style="color:red !important">*</span></label>
                                    <ValidationProvider rules="required" v-slot="v">
                                        <cc-input type="text" id="resale-email" v-model="resale.mail" />
                                        <span class="error">{{ v.errors[0] }}</span>
                                    </ValidationProvider>
                                </div>

                                <div class="col col-md-3 col-lg-4">
                                    <label>Contato:<span style="color:red !important">*</span></label>
                                    <ValidationProvider rules="required" v-slot="v">
                                        <cc-input type="text" id="resale-contact" v-model="resale.contact"></cc-input>
                                        <span class="error">{{ v.errors[0] }}</span>
                                    </ValidationProvider>
                                </div>

                                <div class="col col-md-3 col-lg-4">
                                    <label>Site:<span style="color:red !important">*</span></label>
                                    <ValidationProvider rules="required" v-slot="v">
                                        <cc-input type="text" id="resale-website" v-model="resale.site"></cc-input>
                                        <span class="error">{{ v.errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>

                            <div class="row pt-2">
                                <div v-if="message" class="col-md-12 col-lg-12">
                                    <h4 class="py-4 text-center text-danger">{{message}}</h4>
                                </div>
                                <div class="col d-flex justify-content-center">
                                    <button class="btnFeedbackModal" type="submit">Salvar</button>
                                </div>
                            </div>
                        </form>
                    </ValidationObserver>
				</div>
			</div>
		</div>
	</cc-modal> -->
</template>

<script>
import ErrorHandlerService from '@/services/ErrorHandlerService';
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import ResaleService from "@/services/v3/resales/resale.service";
import UserService from "@/services/v1/user.service";
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import StandardInput from '@/components/ui/inputs/StandardInputV2.vue';
import StandardButton from '@/components/ui/buttons/StandardButton.vue';
import SelectCheckFiltered from '@/components/ui/inputs/SelectCheckFiltered.vue';

export default {
	mixins: [ loaderMixin ],
	props: {
		id: {
			type: Number
		}
	},
	components: {
		ValidationProvider,
		ValidationObserver,
        StandardInput,
        StandardButton,
        SelectCheckFiltered
   	},
  	data() {
		return {
            resale: {},
            loading: true,
            message: null,
            svc: new ResaleService(),
            user_svc: new UserService(),
            title: this.id ? `Editar Revenda` : 'Nova Revenda',
            cities: [],
            states: [],
            available_cities: []
		};
	},
  	methods: {
        setName(value){this.resale.name = value},
        setCnpj(value){this.resale.cnpj = value},
        setPhone(value){this.resale.phone = value},
        setEmail(value){this.resale.mail = value},
        setContact(value){this.resale.contact = value},
        setSite(value){this.resale.site = value},
		save() {
            this.message = null
            this.loading = true
			let callback = data => {
				this.dismiss_loader()
                this.loading = false
                this.close()
                const notification = {
                    type: 'success',
                    message: `Revenda ${this.id ? 'atualizada' : 'criada'} com sucesso!`
                }
                this.$store.dispatch('notification/add', notification)
                this.$emit("reload");
			}
			let callback_error = err => {
                this.loading = false
				this.dismiss_loader()
				if(err.response && err.response.data && err.response.data.errors) {
                    let errors = err.response.data.errors
					this.message = Object.values(errors).flat()[0]
				} else {
					this.message = 'Ops! Houve um erro ao salvar o usuário, tente novamente mais tarde!'
				}
                ErrorHandlerService.handle(err, this.$store);
			}
			if(this.id) {
				this.svc.update(this.resale).then(callback, callback_error)
			} else {
				this.svc.create(this.resale).then(callback, callback_error)
			}

		},
		load_resale() {
            if(this.id) {
                return this.svc.show(this.id).then(response => response.data).then(data => {
                    this.resale = data
                    this.loading = false
                }).catch(error => {
                    ErrorHandlerService.handle(error, this.$store);
                });
            } else this.loading = false
		},
        close() {
            this.$emit("close");
		},
        load_region_data() {
            return this.user_svc.region_data().then(response => response.data).then(data => {
                this.cities = data.cidades.map(c => ({ id: c.cid_id, text: c.cid_nome, est_id: c.est_id }))
                this.states = data.estados.map(c => ({ id: c.est_id, text: c.est_nome, fu: c.est_sigla }))
            })
        },
        setState(value) {
            this.resale.est_id = value.id;
            this.available_cities = this.cities.filter(c => c.est_id == this.resale.est_id)
        }
	},
	async mounted() {
        this.load_resale()
        this.load_region_data()
	}
};
</script>

<style lang="scss" scoped>
	@import "./new-resale.modal";
    .modal-mask {
        position: fixed;
        z-index: 4;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1vw;
    }
    .modal-container {
        width: 85vw;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
    }
    .new-resale-modal-header{
        background-color: #FF7110;
        border-radius: 10px 10px 0 0;
        padding: 1vh 1vw;
        color: white;
        font-size: 2em;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .new-resale-modal-close{
        cursor: pointer;
        font-size: 1.2em;
    }
    .new-resale-modal-body{
        padding: 5vh 3vw;
        height: 80vh;
    }
    .new-resale-modal-body-inputs-line{
        display: flex;
        justify-content: space-between;
        margin-bottom: 2vh;
    }
    .new-resale-modal-input{ width: 45%; }
    .new-resale-modal-body-buttons{
        display: flex;
        justify-content: flex-end;
        gap: 4vw;
        margin-top: 5vh;
        padding-bottom: 5vh;
    }
    @media only screen and (max-width: 950px) {
        .modal-mask{font-size: 1.5em;}
        .modal-container{width: 100vw; overflow: auto; height: 100vh; }
    }
    @media only screen and (max-width: 700px) {
        .new-resale-modal-body-inputs-line{flex-direction: column; gap: 2vh;}
        .new-resale-modal-input{width: 100%;}
        .new-resale-modal-buttons-mobile{width: 45%;}
    }
    @media only screen and (max-width: 500px) {
        .modal-container{width: 115vw;}
    }
</style>